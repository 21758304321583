<template>
  <div>
    <list-template
      :loading="loading"
      :search-config="searchConfig"
      :is-check-input-box="false"
      @onSearch="onSearch"
      has-add
      @onAdd="$router.push('./add')"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleEdit"
    ></list-template>
  </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: 'creator',
          placeholder: '搜索创建人'
        }
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        { prop: 'stu_name', label: '学生姓名' },
        {
          prop: 'type',
          label: '特批类型',
          render(row) {
            const arr = ['', '学费减免', '分期']
            return arr[row.type] || '-'
          }
        },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          render(row) {
            return ['查看']
          }
        }
      ]
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    getData() {
      this.loading = true
      let params = { ...this.search, page: this.page }
      this.tableData = []
      this.$_register('api/recruit/special-privilege/list', { params })
        .then(res => {
          let data = res.data.data
          this.tableData = data.list
          this.total = data.page.total
        })
        .finally(() => {
          this.loading = false
        })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData(val)
    },

    handleEdit(row, text) {
        this.$router.push('./details?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
